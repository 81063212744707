@import "common/variables";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_mixins.scss";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_normalize.scss";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_print.scss";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_scaffolding.scss";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_glyphicons.scss";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_responsive-utilities.scss";
// endbower

@import "common/global";
@import "components/buttons";
@import "components/forms";
@import "components/grid";
@import "layouts/header";
@import "layouts/footer";
@import "layouts/case-study";
@import "layouts/pages";
@import "layouts/interior-page";

// CUSTOM PAGE LAYOUTS
@import "common/fonts";
@import "layouts/home";

.diversity-icon {
    max-width: 105px;
}

.vid-thumb-img {
    position: absolute;
    color: #FFF;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    display: block;
    z-index: 1;
    width: 100%;
}

.video-p .video-play-btn {
    position: relative;
    z-index: 6;
    width: 100%;
    height: 85%;

    img {
        position: absolute;
        top: 56%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 140px;
    }
}

.margin-auto {
    margin: auto;
}

.primary-btn {
    text-align: center;
    text-decoration: none;
    background-color: #ec452e;
    padding: 12px;
    border-width: 0;
    color: #fff;
    text-transform: uppercase;
    -webkit-transition: -webkit-transform .5s ease-in-out;
    -o-transition: -o-transform .5s ease-in-out;
    transition: all .5s ease-in-out;
    display: inline-block;
}

.primary-btn:hover {
    background-color: #dddcdc;
    color: #ec452e;
    text-decoration: none;
}

.job-postings h4 {
    margin: 10px 0;
}

.job-postings h4 a {
    font-size: 20px;
    color: #ec452e;
    line-height: 20px;
}

#top-popup {
    position: fixed;
    top: 0;
    z-index: 500;
    width: 100vw;
    display: none;
}

#top-popup img {
    width: 100%;
}

#top-popup .deskonly {
    display: block;
}

#top-popup .mobileonly {
    display: none;
}

#top-popup.hidden {
    display: none;
}

.covid-banner .header {
    /*top: 6.25vw;*/
    top: 0;
}

.covid-banner .main {
    /*margin-top: calc(6.25vw);*/
    margin-top: 0;
}

.covid-banner .main.with-black-background {
    margin-top: calc(6.25vw + 55px);
}

.black-background-header {
    background-color: black;
    height: 135px;
}

@media (max-width: 640px) {
    #top-popup .deskonly {
        display: none;
    }

    #top-popup .mobileonly {
        display: block;
    }

    .covid-banner .header {
        /*top: 11.8vw;*/
        top: 0;
    }

    .covid-banner .main {
        /*margin-top: calc(11.8vw + 55px);*/
        margin-top: 0;
    }
}
