// PAGE SPECIFIC

#header-loader {
    position: relative;
    height: auto;

    .container {
        position: relative;
    }
}

.header-title.top-center {
    top: 18%;
    bottom: unset;
}

.header-title {
    position: absolute;
    z-index: 9;
    top: 60%;
    text-align: left;
    vertical-align: middle;
    width: 50%;
    height: 30%;
    @include transitionAll(0.5s);

    @media screen and (max-width: 1600px) {
        top: unset;
        bottom: 25%;
    }

    @media screen and (max-width: 1200px) {
        bottom: 20%;
        height: 40%;
    }

    @media screen and (max-width: 768px) {
        left: 4%;
        width: 100%;
        height: auto;
        bottom: 20px;
    }

    @media screen and (max-width: 540px) {
        left: 10%;
    }

    h1 {
        font-size: 70px;
        color: $white;
        line-height: 1em;
        padding: 0px;
        margin: 0px;
        padding-top: 30px;
        font-family: 'mullerreg';

        @media screen and (max-width: 768px) {
            font-size: 4vw;
        }

        @media screen and (max-width: 1199px) {
            top: 50%;
            font-size: 5vw;
        }
    }

    h2 {
        font-family: 'Muller';
        font-size: 27px;
        color: $white;
        line-height: 1em;
        padding: 0px;
        margin: 0px;
        padding-top: 5px;
    }

    h3 {
        font-size: 32px;
        color: $white;
        line-height: 1em;
        padding: 0px;
        margin: 0px;
        padding-top: 5px;
    }

    p {
        font-size: 22px;
        color: $white;
        line-height: 1.2;
        font-family: 'mullerreg';
        margin-top: 10px;

        @media screen and (max-width: 768px) {
            font-size: 2vw;
        }
    }

    .learn-more {
        margin-top: 20px;
    }

    .top-border {
        position: relative;
        width: 400px;
        display: inline-block;
        border-top: solid 2px $lightGrey;
    }
    /***** BREAK 999 *****/
    @media screen and (max-width: 999px) {
        /*display: none;*/
    }
}

.header-title-video {
    top: 50% !important;
}

.header-title-container {
    display: none;
    max-width: 100%;
    text-align: center;
    vertical-align: middle;
    @include transitionAll(0.5s);


    h1 {
        font-family: 'Times New Roman';
        font-size: 70px;
        color: $white;
        line-height: 1em;
    }

    h12 {
        font-family: 'mullermedium';
        font-size: 27px;
        color: $white;
        line-height: 1em;
    }

    h2 {
        font-family: 'Muller';
        font-size: 27px;
        color: $white;
        line-height: 1em;
        padding: 0px;
        margin: 0px;
        padding-top: 5px;
    }

    h3 {
        font-size: 32px;
        color: $white;
        line-height: 1em;
        padding: 0px;
        margin: 0px;
        padding-top: 5px;
    }

    .top-border {
        position: relative;
        width: 400px;
        display: inline-block;
        border-top: solid 2px $lightGrey;
    }
    /***** BREAK 999 *****/
    @media screen and (max-width: 999px) {
        display: block;
    }
}








/*h1 {
	font-family: 'Times New Roman';
	font-size: 70px;
	color: $white;
	line-height: 1em;
	padding: 0px;
	max-width: 85%;
	margin: auto;
	padding-top: 30px;
}*/

.grey-box {
    width: 100%;
    padding: 0px;
    height: 900px;
    background: #1010100D;
    opacity: 1;
}

.white-box {
    position: relative;
    text-align: center;
    background: #FFFFFF;
    opacity: 1;
    margin-bottom: 5px;
}

.orange-box {
    min-width: 100%;
    padding-left: 0;
    padding-right: 0;
    opacity: 1;
    text-align: left;

    #orangeBoxLeftDiv {
        background-color: #E14E1B;
        height: 467px;
        width: 45%;
        min-width: 500px;
        float: left;
    }

    #orangeBoxRightDiv {
        background-color: #E14E1B;
        height: 467px;
        width: 55%;
        float: right;
    }

    #orangeBoxRIghtDivImage {
        height: 467px;
        float: right;
    }
}

.img-padding-top {
    padding-top: 1px;
}

.img-padding-last {
    padding-top: 1px;
}

.img-padding-bottom {
    padding-bottom: 1px;
}

.img-padding-right {
    padding-right: 1px;
}

.img-padding-left {
    padding-left: 1px;
}

@media screen and (max-width: 999px) {
    .grey-box {
        width: 100%;
        height: 100%;
        background: #1010100D;
        opacity: 1;
    }

    .white-box {
        position: relative;
        text-align: center;
        width: 100%;
        background: #FFFFFF;
        opacity: 1;
        padding-left: 50px;
        padding-right: 50px;
    }

    .orange-box {

        #orangeBoxLeftDiv {
            width: 100%;
            height: 420px;
            float: none;
        }

        #orangeBoxRightDiv {
            width: 100%;
            height: auto;
            float: none;
        }

        #orangeBoxRIghtDivImage {
            display: block;
            max-width: 100%;
            height: auto;
            float: none;
        }
    }


    button.contact-btn {
        width: 100%;
        height: 37px;
    }

    .line {
        display: none;
    }

    .img-padding-right {
        padding-right: 0px;
    }

    .img-padding-left {
        padding-left: 0px;
    }

    .img-padding-top {
        padding-top: 0px;
    }

    .img-padding-last {
        padding-top: 2px;
    }

    .img-padding-bottom {
        padding-bottom: 2px;
    }

    /*.only-on-mobile {
		display: block;
	}	*/
}








@media screen and (max-width: 1350px) {

    .orange-box {

        #orangeBoxLeftDiv {
            width: 100%;
            height: 420px;
            float: none;
        }

        #orangeBoxRightDiv {
            width: 100%;
            height: auto;
            float: none;
        }

        #orangeBoxRIghtDivImage {
            display: block;
            max-width: 100%;
            height: auto;
            float: none;
        }
    }
}

.header-title.right {
    left: auto !important;
    right: 250px;
}

.extra-width {
    width: 50%;

    @media (max-width: 1650px) {
        width: 60%;
    }

    @media (max-width: 1600px) {
        width: 70%;
    }

    @media (max-width: 1200px) {
        width: 85%;
    }
}



#video-modal {
    position: absolute;
    top: 0px;
    left: 0;
    right: 0px;
    width: 100%;
    height: 100%;
    margin: auto;
    max-height: 620px;
    background-color: #fff;
    opacity: 0;
    z-index: 99;
    @include transitionAll(0.5s);

    video {
        max-width: 70%;
        margin: auto;
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        text-align: center;
        display: block;
    }

    .video-player {
        position: relative;
        width: 100%;
        height: auto;
    }
    /***** BREAK 999 *****/
    @media screen and (max-width: 999px) {
        // display: none;
        max-height: 250px;

        video {
            max-width: 100%;
        }
    }
}

.videoOpen {
    opacity: 1 !important;
    z-index: 11;
}

//
.content-column-section {
    position: relative;
    display: block;
    padding: 60px 0px;

    li {
        position: relative;
        display: inline-block;
        max-width: 33.333%;
        margin-left: -4px;
        padding: 0px 60px;
        border-right: solid 1px $black;
        vertical-align: top;
        text-align: center;
        height: 230px;

        h3 {
            font-size: 24px;
            line-height: 1em;
            color: $orange;
        }
    }

    li:last-child {
        border-right-width: 0px;
    }
    /***** BREAK 999 *****/
    @media screen and (max-width: 999px) {

        li {
            max-width: 100%;
            height: auto;
            border-right: solid 0px;
        }
    }
}

.content-column-section.special {
    width: 100%;
    float: left;
    padding: 0px 0px 60px;

    li:first-child h3 {
        margin-top: -15px;
    }

    li {
        display: block;
        border-right: 0;
        border-bottom: solid 1px #000;
        padding: 0px;
        max-width: inherit;
        height: inherit;
        text-align: left;
    }

    li:last-child {
        border-bottom: 0px !important;
    }
}

.center-flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media(max-width: 992px) {
    .center-flex {
        flex-direction: column;
    }
}


.content-column-section.special.healthcare {
    padding: 30px 0;

    li:first-child h3 {
        margin-top: 24px;
    }
}

//
.vehicle-wrapper {
    position: relative;
    margin-top: 60px;

    ul {
        position: relative;

        li {
            position: relative;
            list-style: none;
            margin-bottom: 60px;

            h3 {
                color: $orange;
            }

            .left-side, .right-side {
                position: relative;
                display: inline-block;
                margin-left: -4px;
                width: 50%;
                vertical-align: top;

                img {
                    width: 100%;
                    padding-right: 50px;
                }
            }

            .right-side {

                ul {
                    margin-left: 20px;
                    padding-left: 0px;

                    li {
                        list-style: initial;
                        margin-bottom: 5px;

                        p {
                            padding: 0px;
                            margin: 0px;
                        }
                    }
                }
            }
        }
    }
    /***** BREAK 999 *****/
    @media screen and (max-width: 999px) {
        ul {
            li {

                h3 {
                    text-align: center;
                    margin-top: 30px;
                }

                .left-side, .right-side {
                    width: 95%;
                    position: relative;
                    margin: auto;

                    button.view-gallery {
                        display: none;
                    }
                }

                button.view-gallery {
                    width: 85%;
                }
            }
        }
    }
}

//
.style-icon-list {
    position: relative;

    ul {
        position: relative;
        margin: 0px;
        padding: 0px;
        margin-top: 60px;

        .row {
            position: relative;
            width: 100%;
            display: table;
        }

        li {
            position: relative;
            display: inline-block;
            list-style: none;
            font-size: 150px;
            width: 25%;
            border-right: solid 10px #fff;
            border-top: solid 10px #fff;
            background-color: $lighterGrey;
            text-align: center;
            margin-bottom: 5px;
            vertical-align: top;
            display: table-cell;
            padding: 0.5em;
            height: auto;

            .fa {
                font-size: 75px;
            }

            h2 {
                font-size: 24px;
                color: $orange;
            }
        }

        li:last-child {
            border-right-width: 0px;
        }
    }
    /***** BREAK 999 *****/
    @media screen and (max-width: 999px) {
        ul {
            li {
                width: 100%;
                display: block;
                left: 15px;
            }
        }
    }
}

.style-icon-list.vehicle-styles {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
}

.style-icon-list.vehicle-styles ul li {
    padding: 10px;
    border: 0;
    background-color: #FFF;
}

.style-icon-list.vehicle-styles ul li:nth-child(1),
.style-icon-list.vehicle-styles ul li:nth-child(2),
.style-icon-list.vehicle-styles ul li:nth-child(3) {
    border-right: 3px solid #efefef;
    border-bottom: 3px solid #efefef;
}

.style-icon-list.vehicle-styles ul li:nth-child(4) {
    border-bottom: 3px solid #efefef;
}

.style-icon-list.vehicle-styles ul .row:last-child li {
    border-bottom: 0;
}

//
.vehicle-style-loader {
    margin-bottom: 20px;
    margin-top: 60px;

    li {
        position: relative;
    }

    .carousel-nav {
        display: block !important;
        color: $black !important;
    }

    .tile-deck {
        position: absolute;
        margin: auto;
        max-width: 50%;
        bottom: -80px;
        text-align: center;
        left: 0px;
        right: 0px;
        line-height: 1.3em;
    }

    #vehicle-styles-gallery {
        height: 520px !important;

        li {
            width: 100%;
            background-size: cover;
            background-position: center center;
        }

        img {
            width: 100%;
        }
        /***** BREAK 999 *****/
        @media screen and (max-width: 999px) {
            left: -30px;
            height: auto !important;

            li {
                background-position: center 50px;
            }
        }
    }
    /***** BREAK 999 *****/
    @media screen and (max-width: 999px) {
        margin-bottom: 0px;
        margin-top: 0px;
    }
}


//
.popup-gallery {
    position: fixed;
    background-color: rgba(0,0,0,0.7);
    z-index: 1001;
    width: 100%;
    height: 100%;
    display: inline-block;
    top: 0px;
    left: 0px;
    padding-top: 25px;
    display: none;

    .max-width {
        position: relative;
        display: block;
        max-width: 70%;
        margin: auto;
    }

    .lSPager {
        position: absolute;
        bottom: 30px;
        margin: auto;
        text-align: center;
        right: 0px;
        left: 0px;
    }

    .carousel-nav {
        display: block;
        position: absolute;
        top: 45%;
        left: 0px;
        z-index: 3;
        width: 100%;
        color: #fff;
        font-size: 45px;

        .control {
            position: absolute;
            cursor: pointer;
        }

        .right {
            right: 0px;
        }

        .left {
            left: 40px;
        }
    }

    #ui-gallery {
        position: relative;
        top: 0px;
        margin: auto;

        li {
            position: relative;

            img {
                max-width: 100%;
                margin: 0 auto;
            }
        }
    }
    /***** BREAK 999 *****/
    @media screen and (max-width: 999px) {
        padding-top: 125px;

        .max-width {
            max-width: 100%;
        }

        #ui-gallery {
            left: -15px;
        }

        .close-carousel {
            top: -50px;
        }

        .carousel-nav .left {
            left: 0px;
        }
    }
}


//
.form {
    position: relative;
    max-width: 450px;
    margin: auto;
    margin: 30px auto;

    p.required label, form.form span.required label {
        background: none;
        padding-left: 0px;
    }

    p input {
        position: relative;
        width: 60%;
        display: inline-block;
    }

    p select, p textarea {
        width: 60%;
    }

    p label {
        position: relative;
        display: inline-block;
        margin: 0;
        padding: 3px 13px 0 0;
        text-align: right;
        width: 35%;
        vertical-align: top;
    }

    p.submit input {
        margin: 0 10px 0 0;
        padding: 2px 12px;
    }

    p {
        clear: left;
        line-height: 1.4em;
        margin: 3px 5px;
        padding: 3px 2px;
    }

    p .submit {
        margin: 1em .5em .2em 160px;
        padding: 0;
        width: 100px;
    }
}
