footer, .footer { 
	position: relative;
	background-color: $grey;
	height: auto;
	min-height: 50px;
	width: 100%;
	color: $white;
	padding: 60px 0px 20px;
    margin: 60px auto 0;

    .footer-container {
        max-width: 1480px;
        width: 100% !important;

        .split-half {
            display: flex;

            > div {
                width: 50%;
            }
        }
    }

	.only-on-mobile {
		display: none;
	}

	h2 {
		font-size: 24px;
        line-height: 1.35;
	}

    .footer-subscriber {
        position: relative;
        top: -100px;
        padding: 0px 40px;
        background: #EC462C;

        @media(max-width: 1200px) {
            top: 0;
            width: 100% !important;
            max-width: 700px;
            margin: 40px auto 0px;
            padding: 15px 20px;
        }

        ::placeholder {
            color: white;
            opacity: 1;
        }
        input[type=checkbox] {
            position: relative;
            cursor: pointer;

            &:before {
                content: "";
                display: block;
                position: absolute;
                width: 16px;
                height: 16px;
                top: 0;
                left: 0;
                border: 1px solid white;
                border-radius: 0px;
                background-color: white;
            }

            &:checked:after {
                content: "";
                display: block;
                width: 5px;
                height: 10px;
                border: solid black;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
                position: absolute;
                top: 2px;
                left: 6px;
            }
        }
    }

	ul {
		position: relative;
		margin: 0px;
		padding: 0px;

		li {
			list-style: none;

			h3 {
				margin-bottom: 10px;
			}

			p {
				font-size: 16px;
				font-family: 'Times New Roman';
				line-height: 1.5em;
				margin-top: 0px;
				color: #fff;
			}
		}
	}
	//
	.social {
		a {
			color: $white;
			margin-right: 5px;
			padding: 5px 7px;
			@include transitionAll(0.5s);
			font-size: 20px;
			text-align: center;

            &:hover {
                background-color: $orange;
                text-decoration: none;
            }

			.icon-twitter {
				width: 20px;
                display: inline-block;
                position: relative;
                vertical-align: top;

				&:before {
                    content: ' ';
                    background: url('/assets/images/icons/x.svg') center center no-repeat;
                    background-size: cover;
                    width: 15px;
                    height: 15px;
                    position: absolute;
                    left: 4px;
                    top: 4px;
                }
			}
        }
	}

	/***** BREAK 999 *****/
	@media screen and (max-width: 999px) {
		 .subscribe.pull-left { 
		 	float: none !important; 

		 	button.subscribe-btn {
				width: 100%;
				margin-top: 30px;
			}
		 }

		.only-on-mobile {
			display: block;
		}
	}

	/***** BREAK 499 *****/
	@media screen and (max-width: 499px) {

		ul li p {
		    font-size: 15px;
		    font-family: 'Times New Roman';
		    line-height: 1.1em;
		    margin-top: 0;
		    color: #fff;
		}

		h2 {
		    font-size: 18px;
		}
	}	
}	