// HOME PAGE STYLES
// - - - - - - - - - - - - - - - - - - - - - - - - -


//


.video-container,
.case-container {
    width: auto;
    padding-top: 0;

    .case-item {
        padding: 0 1px;

        .plus-icon {
            bottom: 20%;
            background-color: #e24301;
            right: auto;

            @media (max-width: 1400px) {
                bottom: 12%;
            }
        }
    }

    .video-wrapper {
        .thumbnail-rail {
            margin-top: -13px;
            position: relative;

            li {
                .play-icon {
                    color: #FFF;
                    font-size: 45px;
                    text-align: center;

                    @media (max-width: 1700px) {
                        padding-top: 25%;
                    }

                    @media (max-width: 1300px) {
                        padding-top: 30%;
                    }

                    @media (max-width: 890px) {
                        padding-top: 25%;
                    }
                    /*

                    @media (max-width: 600px) {
                        padding-top: 22%;
                    }

                    @media (max-width: 444px) {
                        padding-top: 18%;
                        font-size: 37px;
                    }
                    */

                    .thumbnail-title {
                        font-family: muller,sans-serif;
                        font-weight: lighter;
                        text-align: center;
                        position: absolute;
                        line-height: 18px;
                        font-size: 18px;
                        color: #FFF;
                        top: 150px;
                        z-index: 2;
                        left: 0;
                        right: 0;

                        @media screen and (max-width: 1700px) {
                            top: 120px;
                        }

                        @media (max-width: 1400px) {
                            top: 105px;
                            font-size: 16px;
                            line-height: 16px;
                        }

                        @media screen and (max-width: 1300px) {
                            top: 150px;
                        }

                        @media screen and (max-width: 1150px) {
                            top: 130px;
                        }

                        @media screen and (max-width: 999px) {
                            top: 135px;
                        }

                        @media (max-width: 890px) {
                            top: 120px;
                        }

                        @media (max-width: 768px) {
                            display: none;
                        }
                    }
                }
            }
        }

        .video-banner {
            position: relative;
            display: block;

            @media screen and (max-width: 768px) {
                margin-bottom: 40px;
            }

            img {
                height: 100%;
                width: 100%;

                @media screen and (max-width: 1024px) {
                    width: 150%;
                }

                @media screen and (max-width: 444px) {
                    position: relative;
                    width: 200%;
                    left: -35px;
                }
            }

            .banner-title {
                display: flex;
                flex-direction: column;
                background-color: #e24301;
                position: absolute;
                z-index: 0;
                width: 500px;
                height: 100%;
                top: 0;
                right: 260px;

                @media screen and (max-width: 1550px) {
                    right: 150px;
                }

                @media screen and (max-width: 1350px) {
                    right: 50px;
                }

                @media screen and (max-width: 1024px) {
                    width: 50%;
                    right: 0;
                }

                h2, p {
                    line-height: normal;
                    color: #FFF;
                    margin-bottom: 20px;

                    @media screen and (max-width: 725px) {
                        margin: 2px;
                    }
                }

                .inner-banner {
                    margin: 100px 50px 50px;

                    @media screen and (max-width: 1024px) {
                        margin: 50px 50px 50px;
                    }

                    @media screen and (max-width: 725px) {
                        margin: auto 25px;

                        p {
                            padding: 0;
                        }
                    }

                    @media screen and (max-width: 444px) {
                        margin: auto 3px;
                    }
                }
            }
        }

        #videoTakeover {
            z-index: 999;
            padding-top: 0;

            .w3-container {
                padding: 0;
                min-height: 100vh;

                .w3-display-topright {
                    top: 10px;
                    right: 35px;
                    position: absolute;

                    @media screen and (max-width: 1024px) {
                        right: 25px;
                    }

                    @media screen and (max-width: 768px) {
                        right: 15px;
                        top: 13px;
                    }

                    @media screen and (max-width: 375px) {
                        right: 8px;
                    }
                }

                .closeTakeover {
                    background-color: unset;
                    border-radius: 100%;
                    z-index: 100;
                    padding: unset;
                }

                .static-video {
                    height: 100vh;
                    width: 90%;
                    margin-top: 0;
                    padding-bottom: unset;
                    margin: 0 auto;

                    @media screen and (max-width: 1024px) {
                        width: 87%;
                    }

                    @media screen and (max-width: 768px) {
                        width: 85%;
                    }
                }
            }

            .w3-modal-content {
                min-width: 100%;

                @media screen and (max-width: 768px) {
                    margin: 0;
                }
            }
        }
    }
}

.quote-wrap {
    display: table;
    table-layout: fixed;
    margin: 0 auto;
    padding: 15px;
    margin-bottom: 25px;

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }

    .quote-tile {
        display: table-cell;
        width: 33%;
        text-align: center;
        border-right: 3px solid #000;
        bottom: 0;
        top: 50%;
        padding: 15px;

        @media screen and (max-width: 1200px) {
            padding: 0;
        }

        .borderBottom {
            display: none;
        }

        @media screen and (max-width: 768px) {
            position: relative;
            border-right: none;
            width: 75%;
            margin: auto;

            .borderBottom {
                border-bottom: 1px solid #dddcdc;
                display: block;
                position: relative;
                width: 70%;
                margin: auto;
            }
        }

        blockquote {
            display: flex;
            flex-direction: column;
            margin: 15px;

            h2 {
                font-family: mullerbold,sans-serif;
                font-size: 48px;
                font-weight: 700;
                margin: 0;
                line-height: normal;

                @media screen and (max-width: 1200px) {
                    font-size: 4vw;
                }

                @media screen and (max-width: 768px) {
                    font-size: 30px;
                }
            }

            p {
                margin: 20px 100px;

                @media screen and (max-width: 1500px) {
                    margin: 20px 50px;
                }

                @media screen and (max-width: 1350px) {
                    margin: 20px 25px;
                }

                @media screen and (max-width: 768px) {
                    margin: 20px 0;
                }
            }
        }
    }

    .quote-tile:last-child {
        border-right: none;

        .borderBottom {
            border-bottom: none;
        }
    }
}

.page-carousel {
    position: relative;

    .banner-title {
        position: absolute;
        bottom: 20%;

        h1 {
            font-size: 65px;
            color: $white;
            line-height: 0.9em;
            letter-spacing: 5px;
            font-family: 'mullerreg';
            margin-bottom: 0;
        }

        p {
            font-size: 22px;
            color: $white;
            line-height: 1.2;
            font-family: 'mullerreg';
            margin-top: 10px;
        }

        .learn-more {
            margin-top: 0px;
        }
    }

    #image-gallery, #modal-gallery {
        position: relative;

        li {
            width: 100%;
            height: 820px;
            box-shadow: inset 900px 150px 20em -5em rgba(0,0,0,0.55);
            background-size: cover;
            background-position: center center;
        }
        /* li:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(to right, #000 0%, transparent 21%);
        }*/

        img {
            width: 100%;
        }

        li#contact {
            background-position: center right;
        }
    }

    .carousel-nav {
        display: none; //
        position: absolute;
        top: 45%;
        left: 0px;
        z-index: 3;
        width: 100%;
        color: #fff;
        font-size: 45px;

        .control {
            position: absolute;
            cursor: pointer;
        }

        .right {
            right: 40px;
        }

        .left {
            left: 30px;
        }
    }
    /***** BREAK 1199 *****/
    @media screen and (max-width: 1199px) {

        .banner-title {
            width: 970px;
            text-align: left;
            margin: auto;
            padding-left: 15px;
            right: 0px;
            left: 0px;
            bottom: 10%;

            h1 {
                font-size: 4vw !important;
            }

            p {
                font-size: 2vw !important;
            }
        }

        .learn-more {
            display: none;
        }

        #image-gallery li {
            width: 100%;
            height: calc(100vw * 0.43);
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            position: relative;
            box-shadow: inset calc(100vh * 0.6) 1em 20em -5em rgba(0,0,0,.55);
            /***** BREAK 768 *****/
            @media screen and (max-width: 768px) {
                box-shadow: inset calc(100vh * 0.3) 1em 20em -5em rgba(0,0,0,.55);
                height: calc(100vw * 1.33);
            }
        }

        #image-gallery li#contact {
            background-position: center right;

            @media screen and (min-width: 784px) {
                .banner-title.mobile-only {
                    display: none;
                }
            }
        }
    }
    /***** BREAK 999 *****/
    @media screen and (max-width: 992px) {

        .banner-title {
            // top: 35%;
            width: 750px;

            h1 {
                font-size: 36px;
            }
        }

        .carousel-nav {

            .right {
                right: 0px;
            }

            .left {
                left: 0px;
            }
        }

        .full-list-item {
            background-position: top center;
        }
    }
    /***** BREAK 768 *****/
    @media screen and (max-width: 768px) {

        .banner-title {
            width: 100%;
            top: unset;
            bottom: 5%;
            padding-left: 30px;
        }
    }

    @media screen and (max-width: 540px) {
        /*
        .banner-title {
            padding-left: 15px;
        }*/
    }
}

//
.lSSlideOuter .lSPager.lSpg > li.active a,
.lSSlideOuter .lSPager.lSpg > li:hover a {
    background-color: $orange;
}

.lSSlideOuter .lSPager.lSpg > li a {
    border-radius: 0px;
}


//
.cta-wrapper {
    position: relative;
    margin-top: 40px;
    margin-bottom: 60px;

    ul {
        margin: 0px;
        padding: 0px;
        display: table;
        width: 100%;

        li:hover {
            .ico {
                color: $orange;
            }

            button {
                background-color: $orange;
            }
        }

        li {
            position: relative;
            list-style: none;
            width: 33%;
            display: inline-block;
            text-align: center;
            background-color: #fafafa;
            vertical-align: top;
            vertical-align: top;
            display: table-cell;
            padding: 20px 20px;
            margin: 20px;
            border-right: solid 20px $white;
            cursor: pointer;
            padding-bottom: 50px;

            .top {
                top: 15px;
            }

            .fa {
                font-size: 75px;
            }

            a {
                color: $white;
            }

            .ico {
                font-size: 50px;
                @include transitionAll(0.5s);
            }

            .border-bottom {
                border-bottom: solid 1px $lightGrey;
            }

            .extra-pad {
                padding-left: 30px;
                padding-right: 30px;
            }

            p {
                padding-bottom: 30px;
            }

            button {
                position: absolute;
                bottom: 20px;
                margin: auto;
                left: 0px;
                right: 0px;
            }

            .ico {
                width: 90px;
                height: 75px;
                position: relative;
                margin: auto;
                vertical-align: middle;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        li:last-child {
            border-right-width: 0px;
        }
    }
    /***** BREAK 999 *****/
    @media screen and (max-width: 999px) {

        ul {
            padding: 20px;

            li {
                width: 100%;
                display: block;
                margin: 10px 0px;
                border-right: 0px;
            }
        }
    }
}


//
.video-wrapper {
    position: relative;
    margin-bottom: 100px;

    #thumbnail-gallery {
        margin-left: -40px;
    }


    p {
        text-align: center;
    }
    //
    .static-video {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 25px;
        height: 0;
        margin-top: 30px;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .thumbnail-rail {
        position: relative;
        margin-top: 30px;

        img {
            width: 100%;
        }

        li {
            position: relative;
            @include transitionAll(0.5s);
            cursor: pointer;
            border-right: solid 2px #fff;

            .play-icon {
                position: absolute;
                top: 0px;
                left: 0px;
                z-index: 2;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,0.7);
                color: $white;
                font-size: 25px;
                padding-top: 30%;
                text-align: center;
                opacity: 0px;
                @include transitionAll(0.5s);
            }
        }


        li:hover, li.selected {
            .play-icon {
                opacity: 1;
            }
        }

        .thumbnail-nav {
            position: absolute;
            top: 45%;
            color: $black;
            width: 100%;
            z-index: 10;

            .control {
                position: absolute;
                cursor: pointer;
                font-size: 20px;
            }

            .right {
                right: 10px;
            }

            .left {
                left: 10px;
            }
        }
    }
    //
    /***** BREAK 999 *****/
    @media screen and (max-width: 999px) {

        p {
            padding: 0px 40px;
        }

        #thumbnail-gallery {
            margin-left: 0px;
            margin-right: 40px;
        }

        .thumbnail-rail .thumbnail-nav .right {
            right: 10px;
        }

        .thumbnail-rail .thumbnail-nav .left {
            left: 10px;
        }
    }
}

//
.quotes {

    .lSPager {
        margin-top: 5px;
        position: absolute;
        top: 90%;
        margin: auto;
        left: 0px;
        right: 0px;
    }

    .lSSlideOuter .lSPager.lSpg > li a {
        background-color: #b8b8b8;
    }

    .lSSlideOuter .lSPager.lSpg > li.active a, .lSSlideOuter .lSPager.lSpg > li:hover a {
        background-color: #ec452e;
    }
}

.quotes {
    margin-bottom: 60px;

    .item {
        padding: 70px 0px;
    }
}

//
#quote-gallery {
    position: relative;
    background-size: cover;
    width: 100%;
    padding: 20px 0px;

    li {
        position: relative;
        width: 100%;
    }

    .quote-tile {
        position: relative;
        width: 50%;
        text-align: center;
        z-index: 10;
        margin: auto;
        right: 0px;
        left: 0px;
        top: 30%;

        h2 {
            font-size: 32px;
            color: #fff;
        }

        .author {
            font-size: 25px;
            line-height: 1.3em;
            font-style: italic;
        }

        blockquote {
            margin: 1.5em 10px;
            padding: 0.5em 10px;
            quotes: "\201C""\201D""\2018""\2019";
            font-family: arial;
            color: #fff;
            font-size: 25px;
            line-height: 1.3em;
        }

        blockquote:before {
            color: #ccc;
            content: open-quote;
            font-size: 4em;
            line-height: 0.1em;
            margin-right: 0.25em;
            vertical-align: -0.4em;
            color: $orange;
        }

        blockquote:after {
            color: #ccc;
            content: close-quote;
            font-size: 4em;
            line-height: 0.1em;
            margin-left: 0.25em;
            vertical-align: -0.6em;
            color: $orange;
        }

        blockquote p {
            display: inline;
        }
    }
    /***** BREAK 999 *****/
    @media screen and (max-width: 999px) {

        .quote-tile {
            width: 90%;
            margin: auto;
        }

        .quote-tile blockquote {
            font-size: 18px;
            line-height: 1.1em;
        }
    }
    /***** BREAK 499 *****/
    @media screen and (max-width: 499px) {

        .quote-tile {
            width: 90%;
            margin: auto;
        }
    }
}


//
.mobile-case-wrapper {

    .carousel-nav {
        display: block;
        position: relative;
        top: 10px;
        left: 0;
        z-index: 3;
        width: 100%;
        color: #000;
        font-size: 25px;
        height: 50px;
        z-index: 99;
        width: 100%;
        background-color: #fff;

        .control {
            position: absolute;
            cursor: pointer;
        }

        .right {
            right: 0px;
        }

        .left {
            left: 0px;
        }

        .gallery-counter {
            position: relative;
            width: 80%;
            text-align: center;
            margin: auto;
            top: 8px;
            font-size: 14px;
            font-family: 'TimesNewRoman', 'Times New Roman', Times, Baskerville, Georgia, serif;
        }
    }

    .slick-slide {
        width: 100%;

        img {
            width: 100vw;
        }
    }





    ul {
        position: relative;
        width: 100%;
        display: inline-block;
        padding-left: 0px;

        li {
            position: relative;
            list-style: none;
            margin-bottom: 0px;
            cursor: pointer;

            .content-hidden {
                display: none;
                padding: 15px;

                .case-mobile-title-block {
                    border-bottom: solid 1px #000;
                    padding-bottom: 5px;
                }

                h2 {
                    font-size: 24px;
                    line-height: 1.3em;
                }

                h3 {
                    font-size: 18px;
                    color: $orange;
                }

                h5 {
                    font-size: 18px;
                }

                #mobile-case-gallery {

                    ul {
                        position: relative;

                        li {
                            position: relative;

                            img {
                                width: 100%;
                            }
                        }
                    }
                }
            }

            .list-title {
                color: #fff;
                text-transform: uppercase;
                background-color: #1f1e24;
                height: 40px;
                border: solid 1px #79787c;

                span {
                    display: inline-block;
                    width: 75%;
                    vertical-align: middle;
                }
            }

            .class-img {
                position: relative;
                margin-top: 0px;

                img {
                    width: 100%;
                }
            }

            .plus-sign {
                background-color: #000;
                margin-right: 10px;
                position: relative;
                display: inline-block;
                padding: 10px;
            }
        }
    }
}



//
#tour-map-wrapper {
    position: relative;
    margin-bottom: 30px;

    #map {
        width: 100%;
        height: 550px;
    }
    /***** BREAK 999 *****/
    @media screen and (max-width: 999px) {
        display: none;
    }
}

//
#twitter-wrapper {
    position: relative;
    margin-bottom: 50px;

    h2 {
        font-size: 36px;
        text-align: center;

        a {
            @include transitionAll(0.2s);
            text-decoration: none;
        }

        a:hover {
            color: $orange;
        }
    }

    .twitter-logo {
        position: relative;
        font-size: 71px;
        color: #00aadd;
        text-align: center;
        cursor: pointer;

        a {
            color: #00aadd;
            @include transitionAll(0.2s);
            text-decoration: none;
        }

        a:hover {
            color: $orange;
        }
    }

    #tweet-slider {
        position: relative;
        margin-bottom: 30px;

        li {
            text-align: center;

            p {
                font-size: 20px;
                line-height: 1.1em;
                position: relative;
                top: 0px;
                max-width: 100%;
                margin: auto;

                a {
                    color: $orange;
                    @include transitionAll(0.5s);
                }

                a:hover {
                    opacity: 0.5;
                    text-decoration: none;
                }
            }
        }
    }

    .carousel-nav {
        display: block;
        position: absolute;
        top: 0px;
        left: 0;
        z-index: 3;
        width: 100%;
        color: #000;
        font-size: 25px;

        .control {
            position: absolute;
            cursor: pointer;
        }

        .right {
            right: -40px;
        }

        .left {
            left: -30px;
        }
        /***** BREAK 999 *****/
        @media screen and (max-width: 999px) {

            .right {
                right: -20px;
            }

            .left {
                left: -30px;
            }
        }
    }
    /***** BREAK 999 *****/
    @media screen and (max-width: 999px) {
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

        h2 {
            a {
                line-height: 1.3em;
            }
        }
    }
    /***** BREAK 480 *****/
    @include sm-max {

        h2 {
            font-size: 25px;
        }

        #tweet-slider li p {
            font-size: 15px;
            padding: 0px 50px;
            line-height: 1.1em;
        }
    }
}


// MEDIA QUERIES
// - - - - - - - - - - - - - - - - - - - - - - - - -


// 480
@include sm-max {
}




// 1280
@include xl-min {
}
