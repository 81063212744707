// HEADER ELEMENT STYLES
// - - - - - - - - - - - - - - - - - - - - - - - - -

header, .header {
    position: absolute;
    background-color: $transparent;
    height: 55px;
    width: 100%;
    color: $white;
    top: 0px;
    left: 0px;
    z-index: 999;

    .container {
        @media screen and (max-width: 500px) {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
    }

    .open-close-nav {
        z-index: 999;
    }

    .brand-logo {
        position: relative;
        width: 113px;
        height: 127px;
        z-index: 10;
        @include transition(all);
    }

    .desktop-full-nav {
        position: relative;
        display: inline-block;
        margin: 0;
        padding: 0px;
        margin-left: 0px;

        li {
            position: relative;
            display: inline-block;
            list-style: none;
            padding: 5% 0 0 0;
            height: 112px;
            margin-right: -3px;
            @include transitionAll(0.5s);
            cursor: pointer;

            a {
                color: $white;
                text-align: center;
                vertical-align: middle;
                text-decoration: none;
                top: 5px;
                display: inline-block;
                width: 100%;
                height: 100%;
                position: relative;
                text-transform: uppercase;
                font-size: 12px;
                font-family: 'mullermedium';
                @include transitionAll(0.5s);
            }
        }

        li:last-child {
            margin-right: 0px;
        }

        li:hover {
            background-color: $white;

            a {
                color: $black;
                text-decoration: none;
            }
        }

        .contact-btn {
            background-color: #2e2e33;
        }
    }

    .brand-logo:hover {
        opacity: 0.9;
    }
    //
    .lines-button {
        position: relative;
        float: right;
        cursor: pointer;
        height: 40px;
        margin: 0;
        padding: 12px 9px;
        color: $orange;
        background: transparent;
        border: 0;
        outline: none;
        text-shadow: none;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        @include transition(all);
        top: 5px;
        z-index: 11;
        display: none;

        &:active {
            -moz-transition-property: none;
            -webkit-transition-property: none;
            -o-transition-property: none;
            transition-property: none;
        }

        &.open {
            .lines {
                background: transparent;

                &:before,
                &:after {
                    background: $orange;
                    top: 0;
                    width: 28px;
                    @include transform-origin(50%, 50%);
                }

                &:before {
                    @include rotate(45deg);
                }

                &:after {
                    @include rotate(-45deg);
                }
            }
        }

        .lines {
            position: relative;
            display: block;
            width: 28px;
            height: 4px;
            background: $orange;
            transition: 0.3s;

            &:before,
            &:after {
                display: inline-block;
                width: 28px;
                height: 4px;
                background: $orange;
                transition: 0.3s;
                position: absolute;
                left: 0;
                content: '';
                -webkit-transform-origin: 0.28571rem center;
                transform-origin: 0.28571rem center;
            }

            &:before {
                top: 8px;
            }

            &:after {
                top: -8px;
            }
        }
    }

    .lines-button:hover {
        color: $white;

        .lines {

            &:before,
            &:after {
                background-color: $white;
            }
        }
    }

    .nav {
        position: absolute;
        top: -580px;
        width: 100%;
        background-color: rgba(0,0,0,.8);
        z-index: 2;
        @include transitionAll(0.5s);

        ul {
            position: relative;
            margin: 0px;
            padding: 0px;
            margin: 0px 100px;

            li {
                position: relative;
                display: inline-block;
                vertical-align: top;
                width: 25%;
                margin-left: -4px;
                text-align: center;
                font-size: 50px;
                padding: 45px;
                border-right: solid 1px $headBorder;
                border-top: solid 1px $headBorder;
                @include transitionAll(0.5s);
                cursor: pointer;

                a {
                    color: $white;
                    @include transitionAll(0.5s);
                }

                svg {
                    fill: $white;
                    @include transitionAll(0.5s);
                }

                h5 {
                    text-align: center;
                    color: $white;
                    position: relative;
                    line-height: 1.1em;
                    font-size: 12px;
                    margin: 0px;
                    padding: 0px;
                    margin-top: 10px;
                    @include transitionAll(0.5s);
                }
            }

            li.no-top {
                border-top-width: 0px;
            }

            li:nth-child(4n) {
                border-right-width: 0px;
            }

            li:hover {
                color: $orange;

                a {
                    color: $orange;
                    text-decoration: none;
                }

                svg {
                    fill: $orange;
                }

                h5 {
                    color: $orange;
                }
            }
        }
    }

    .nav-open {
        top: 55px;
    }
    /***** BREAK 1199 *****/
    @media screen and (max-width: 1199px) {

        .nav {
            display: none;
        }

        .lines-button {
            display: block;
        }

        .lines-button:hover {
            color: $orange;

            .lines {

                &:before,
                &:after {
                    background-color: $orange;
                }
            }
        }


        .open-close-nav {
            z-index: 999;
            float: none !important;
            top: 0px;
            right: 5px;
            width: 400px;
            position: absolute;
        }

        .desktop-full-nav {
            position: absolute;
            margin: 0;
            padding: 0;
            left: 0;
            background-color: #1f1e24;
            width: 100%;
            padding-top: 120px;
            text-align: left;
            top: 55px;
            display: none;

            li {
                width: 100%;
                display: block;
                position: relative;
                height: auto;
                padding-left: 20px;
                border-bottom: solid 2px #18181c;
                padding-bottom: 25px;

                a {
                    text-align: left;
                    width: 95%;
                }

                a:after {
                    font-family: 'Glyphicons Halflings';
                    content: "  \e080";
                    float: right;
                    margin-right: 10px;
                }
            }
        }
    }
    /***** BREAK 999 *****/
    @media screen and (max-width: 999px) {

        .desktop-full-nav {
            left: 0;
            top: 0px;
        }
    }
    /***** BREAK 499 *****/
    @media screen and (max-width: 499px) {

        .brand-logo, header .brand-logo {
            position: relative;
            width: 63px;
            height: 127px;
            z-index: 10;
        }

        .desktop-full-nav {
            /*left: 19px;*/
            padding-top: 70px;

            li {
                a {
                    width: 90%;
                }
            }
        }
    }
    /***** BREAK 320 *****/
    @media screen and (max-width: 320px) {

        .desktop-full-nav {
            /*left: 15px;*/
            padding-top: 60px;

            li {
                padding-bottom: 10px;

                a {
                    top: -5px;
                }
            }
        }
    }
}

.not-on-desktop {
    display: none !important;
}

@media (max-width: 1199px) {
    .not-on-desktop {
        display: block !important;
    }
}

/* Navigation bar start */
.desktop-full-nav {

    li {
        position: relative;
        width: 160px;

        a {
            outline: none;
        }

        .nav-dropdown {
            display: none;
            background-color: #1f1e24;
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;

            ul {
                list-style: none;
                padding-left: 0;

                li {
                    width: 100%;
                    height: auto;
                    display: block;
                    padding: 0;
                    margin: 0;

                    a {
                        color: #FFF !important;
                        line-height: 12px;
                        padding: 15px 15px 15px 10px;
                        border-bottom: 1px solid #8E9092;
                        display: block;
                        width: calc(100% - 20px);
                        margin: auto;
                        top: 0;
                        text-align: center;
                    }

                    a:focus {
                        outline: none;
                    }
                }

                li:hover {
                    background-color: #FFF;

                    a {
                        color: #1f1e24 !important;
                    }
                }

                li:last-child a {
                    border-bottom: none;
                }
            }

            .nav-sub-dropdown ul {
                li a {
                    color: #FFF !important;
                    line-height: 12px;
                    padding: 15px 15px 15px 10px;
                    border-bottom: 1px solid #8E9092;
                    display: block;
                    width: calc(100% - 20px);
                    margin: auto;
                    top: 0;
                    text-align: center;
                }


                li a:hover {
                    color: #1f1e24 !important;
                }

                li:last-child a {
                    border-bottom: none;
                }
            }
        }

        .nav-dropdown.active {
            display: block;
        }

        .pluslogo {
            font-size: 20px;
            position: absolute;
            right: 5px;
        }
    }

    .nav-dropdown {
        min-width: 160px;

        li:hover .nav-sub-dropdown {
            display: block !important;
        }
    }

    .nav-sub-dropdown {
        top: 0 !important;
        left: 100% !important;
        min-width: 195px;
        display: none !important;

        ul li a {
            color: #FFF !important;
        }
    }
}

@media screen and (min-width: 1200px) {
    .mobilenav-only {
        display: none !important;
    }
}

@media screen and (max-width: 1199px) {

    .mobilenav-only {
        float: right !important;
    }

    .mobilenav-nopad {
        padding-top: 0px !important;
    }

    .navonly-desktop {
        display: none !important;
    }

    .header,
    header {
        .desktop-full-nav {
            .op-option {
                display: none;
                background-color: #2e2e33;

                &.mobile-sub-dropdown {
                    border-top: 2px solid #000;
                    background: #fff;
                    margin-top: 20px;
                }
            }

            .slide-op {
                a:focus {
                    outline: none;
                }

                a::after {
                    content: " \2b";
                }

                a.changed::after {
                    content: " \2212";
                }

                &.slide-plus {
                    &:after {
                        content: " \2b";
                    }

                    &.changed::after {
                        content: " \2212";
                    }
                }
            }
        }
    }
}
/* Navigation bar end */
