/*********** CASE STUDY STYLE SETS ****************/ 

#case-study-wrapper {

	position: relative;
	margin-bottom: 60px;
	overflow: hidden;

	.load-more {
		text-align: center;
		position: relative;
		margin: 30px auto 0;
		display: inherit;
	}
 
	p.center {
		margin-bottom: 30px;
	}

	.case-block {
		position: relative;
		padding-bottom: 2px;
		opacity: 0px;
		@include transitionAll(.5s);
		height: 0px;
		overflow: hidden;

		.margin-top-30 {
			margin-top: 15px;
		}

		.block-inner {
			position: relative;
		}

		.popover-title {
			position: absolute;
			z-index: 10;
			width: 100%;
			height: 100%;
			opacity: 0;
			@include transitionAll(.5s);
			background-color: rgba(0,0,0,0.8);
			display: flex;
		    flex-direction: column;
		    justify-content: center;
		    align-items: center;  

			h5 {
				color: $white;
				font-size: 18px;
				position: relative;
				vertical-align: middle;
				text-align: center;
				width: 85%;
                @media screen and (max-width: 1200px) {
                    margin-top: 0;
                }
			}
		}

		.popover-title:hover {
			opacity: 1;
		}

		img {
			width: 100%;
		}

		.case-item {
			cursor: pointer;
			overflow: hidden;
			background-size: cover;
		}

		.post-thumb-sm {
			position: relative;
		}

		.half-height {
			position: relative;
			height: 50%;
			padding: 15px;
			margin: -15px;

			.popover-title {
				width: 95%;

			}
  
			img {
				width: 95%;
			}
		}
	}

	.on { 
		opacity: 1;
		height: auto;
		@include transitionAll(.5s);
	 }

	.author {
	  font-style: italic;
	  font-size: 18px;
      font-family: TimesNewRoman,'Times New Roman',Times,Baskerville,Georgia,serif;
	}

	blockquote {
		padding: 0px;
		margin: 0px;
		font-style: italic;
		font-size: 18px;
		font-family: 'TimesNewRoman', 'Times New Roman', Times, Baskerville, Georgia, serif;;
		margin-top: 30px;
		position: relative;
		display: block;
		line-height: 1.4em;
	}

	blockquote:before {
	  content: open-quote;
	  line-height: 0.1em;
	  margin-right: 0.25em;
	  vertical-align: -.1em;
	}

	blockquote:after {
	  content: close-quote;
	  line-height: 0.1em;
	  margin-left: 0.25em;
	  vertical-align: -0.2em;
	}

	blockquote p {
	  display: inline;
	}

	.padd-top {
		margin-top: 30px;
	}

	.pull-left-org {
		margin-left: -15px;
	}

	.pull-right-org {
		margin-right: -15px;
		padding-right: 0px;
	}


	/***** BREAK 1199 *****/
	@media screen and (max-width: 1199px) {


	}

	/***** BREAK 999 *****/
	@media screen and (max-width: 999px) {

		
		.case-wrapper { display: none; }
		.load-more { display: none; }
		blockquote { display: none; }
		.author { display: none; }
		 
	}

	/***** BREAK 499 *****/
	@media screen and (max-width: 499px) {

		.case-wrapper {
			padding: 0px 20px;
		}

		.case-block .half-height {
		    position: relative;
		    height: 100%;
		    width: 100%;
		    padding: 0px;
		    margin: 15px;
		    left: -7.5px;
		}

		.pull-right-org {
		    margin-right: -0px;
		    padding-right: 0;
		    left: -7.5px;
		}

		.case-block .popover-title h5 {
		    font-size: 18px;
		    top: 20%;
		    left: 20%;
		}
		
	}	

	/***** BREAK 499 *****/
	@media screen and (max-width: 375px) {

	  
	} 

}

// 
.case-modal {

	position: relative;
	z-index: 12;
	width: 100%;
	height: 100%;
	background-color: $white;
	margin-bottom: 60px;
	display: none;

	#modal-gallery {
		position: relative;
		

		li {
			width: 100%;
			height: 620px;
			background-size: cover;
			background-position: center center;
		}

		img { width: 100%; }

		/***** BREAK 499 *****/
		@media screen and (max-width: 1199px) {
		  margin: 0px;
		  padding: 0px;
		  left: -10px;
		} 
	}


	.case-content {
		
	}

	.close-modal {
		position: absolute;
		right: 300px;
		top: 0px;
		cursor: pointer;
		 
		h2{
			font-size: 30px;
			margin: 0px;
			padding: 0px;
			margin-top: 10px;
		}
	}

	.title-side {
		position: relative;
		display: inline-block;
		width: 35%;
		margin-left: -4px;
		vertical-align: top;

		h3 { 
			font-size: 36px;
			line-height: 1.1em;
			width: 65%;
			margin-bottom: 0px;
			padding: 0px;
			margin-top: 20px;
		}

		h5 {
			font-size: 24px;
			color: $red;
			line-height: 1.1em;
			width: 65%;
			margin-top: 18px;
		}

	}

	.case-breakdown {
		position: relative;
		display: inline-block;
		width: 65%;
		margin-left: -4px;
		vertical-align: top;
		margin-bottom: 60px;

		h3 { 
			font-size: 24px;
		}
	}

	.page-gallery {
		position: relative;
		display: block;
		margin: auto;

		li {
			width: 100%;
			height: 620px;
			background-size: cover;
		}

		.carousel-nav {
			position: absolute;
			top: 45%;
			left: 0px;
			z-index: 3;
			width: 100%;
			color: $black;
			font-size: 45px;

			.control {
				position: absolute;
				cursor: pointer;
			}

			.right {
				right: -60px;
				@media screen and (max-width: 1199px) {
				 right: -30px;
				} 
			}

			.left {
				left: -60px;
			}

		}

	}

	.study-nav {
		position: relative;
		border-top: solid 1px $grey;
		border-bottom: solid 1px $grey;
		padding: 20px 0px;

		a {
			@include transitionAll(.5s);
		}

		a:hover {
			color: $orange;
			text-decoration: none;
		}

	}
}

// View Tour
.view-tour-body {
    margin: 100px auto;

    img {
        width: 100%;
        height: 100%;
    }

    .header-content .extra-width-vt {
        display: inline-block;

        h2 {
            color: #ec452e;
            padding: 0;
            padding-top: 30px;
            line-height: 1em;
            font-size: 70px;
            margin: 0;
            font-size: 24px;
            display: inline-flex;
            font-weight: bold;
        }

        .post-title {
            padding-top: 5px;
        }

        .bottom-border {
            position: relative;
            width: auto;
            display: block;
            border-bottom: solid 2px #dddcdc;
            margin: 20px 0 0;
        }
    }

    .post-content .post-subtitle {
        font-weight: 600;
    }

    .view-tour-grid {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-bottom: 60px;
        margin-top: 0;
        height: auto;

        @media (max-width: 450px) {
            flex-direction: column;
        }

        .grid-post {
            border: 1px solid #cccbcb;
            margin: 10px;
            display: flex;
            flex-direction: column;
            flex: 1 31%;
            opacity: 1;
            height: auto;
            overflow: hidden;
            transition: opacity 1s ease-in-out;
            cursor: pointer;

            @media screen and (max-width: 991px) {
                flex: 0 1 46%;
            }

            @media screen and (max-width: 550px) {
                flex: 0 1 45%;
            }

            img {
                height: 100%;
                width: 100%;
            }

            .grid-post-body {
                padding: 0 15px;
                height: 100%;
                position: relative;

                h2, h6 {
                    font-weight: 600;
                }

                h2 {
                    font-size: 19px;
                    line-height: 1;
                    color: #000;
					margin-bottom: 30px;
                }

				a {
					position: absolute;
					bottom: 5px;
				}

                h6 {
					font-size: 14px;
                    color: #ec452e;
                }

                a:focus, a:hover {
                    text-decoration: none;

                    h6 {
                        color: #000;
                    }
                }
            }
        }

        .grid-post:last-child:nth-child(3n+1) {
            flex: 0 1 31.5%;

            @media screen and (max-width: 992px) {
                flex: 0 1 46%;
            }

            @media screen and (max-width: 550px) {
                flex: 0 1 45%;
            }
        }
    }

    .view-tour-slider {

        $thumbSize: 120px;

        .tour-slide {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            height: $thumbSize * 2;
            margin: 10px;
            cursor: pointer;

            .pswp-image-main {
                visibility: hidden;
                width: unset;
                height: unset;
            }
        }
        /*==========slick============*/
        .slick-next {
            position: relative;
            left: 1160px;
            top: -130px;
        }

        .slick-prev {
            position: relative;
            top: 100px;
            left: -100px;
        }

        .slick-next, .slick-prev {
            visibility: hidden;
        }

        .slick-dots {
            padding: 0;
            margin: 0;

            li {
                width: 14.2857%;
                display: inline-block;
                visibility: hidden;
                margin: 0;
                padding: 0;

                button {
                    height: 5px;
                    padding: 5px;
                    width: 100%;
                    padding-bottom: 0px;
                    line-height: normal;
                    margin: 0 0 .625rem;
                    margin-bottom: 0.625rem;
                    position: relative;
                    text-decoration: none;
                    text-align: center;
                    -webkit-appearance: none;
                    -webkit-border-radius: 0;
                    display: inline-block;
                    font-size: 1rem;
                    font-weight: normal;
                    background-color: #cccbcb;
                    color: #fff;
                    transition: background-color 300ms ease-out;
                    visibility: visible;
                    border: none;
                }

                button:focus {
                    outline: none;
                }

                button:before {
                    content: "";
                }
            }

            li.slick-active {
                visibility: visible;

                button {
                    background-color: #1f1e24;
                }

                button:focus {
                    outline: none;
                }
            }
        }
    }

    .mra-image-gallery {
        display: flex;
        flex-wrap: wrap;
        $thumbSize: 120px;

        .image-container-pswp {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            width: 50%;
            width: $thumbSize * 2;
            height: $thumbSize * 2;
            margin: 10px;
            cursor: pointer;

            .pswp-image-main {
                visibility: visible;
            }
        }
    }
}