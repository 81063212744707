//
button.learn-more-btn {
	background-color: $grey;
	padding: 12px 0px;
	width: 205px;
	border-width: 0px;
	color: $white;
	text-transform: uppercase;
	@include transitionAll(.5s);
}

button.learn-more-btn:hover {
	background-color: $highlight;
	a {
    	text-decoration: none;
    }
}

a.learn-more {
	background-color: $orange;
	padding: 12px 0px;
	width: 205px;
	border-width: 0px;
	color: $white;
	text-align: center;
	text-transform: uppercase;
	@include transitionAll(.5s);
}

a.learn-more:hover {
	background-color: $white;
	color: $orange;
}


button.view-gallery {
	background-color: $white;
	padding: 12px 0px;
	width: 155px;
	border-width: 0px;
	color: $orange;
	border: solid 1px $orange;
	text-transform: uppercase;
	@include transitionAll(.5s);
}

button.view-gallery:hover {
	background-color: $orange;
	color: $white;
	border: solid 1px $white;
}


button.subscribe-btn {
	background-color: $orange;
	padding: 12px 0px;
	width: 205px;
	border-width: 0px;
	color: $white;
	text-transform: uppercase;
	@include transitionAll(.5s);
}

button.subscribe-btn:hover {
	background-color: $lightGrey;
	color: $highlight;

	a {
		color: $highlight;
	}
}

//
.plus-icon {
	position: absolute;
	bottom: 0px;
	right: 0px;
	padding: 15px;
	background-color: $black;
	color: $white;
	z-index: 12;

	h6 {
		padding: 0px;
		margin: 0px;
		color: $white;
		font-size: 30px;
	}
}

//
.video-play-btn {
	position: absolute;
    z-index: 9;
    font-size: 250px;
    color: #FFF;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
    @include transitionAll(0.5s);
	opacity: 1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 150px;
    height: 150px;
    text-align: center;
    display: block;
    z-index: 100;

    /***** BREAK 999 *****/
	@media screen and (max-width: 999px) {
		 // display: none; 	 
	}
}

.close-carousel {
	position: absolute;
	right: -10px;
	color: $orange;
	font-size: 25px;
	cursor: pointer;
	top: 20px;
	z-index: 99999;
	@include transitionAll(0.5s);
	background-image: url(assests/images/back-bg.png);
	background-repeat: repeat;
	display: inline-block;
	padding: 25px;
}

.close-carousel:hover {
	color: $white;
}


button.contact-btn {
	background-color: $orange;
	padding: 12px 0px;
	width: 205px;
	border-width: 0px;
	color: $white;
	text-transform: uppercase;
	@include transitionAll(.5s);
}

button.contact-btn:hover {
	background-color: $lightGrey;
	color: $highlight;

	a {
		color: $highlight;
	}
}
