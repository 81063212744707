// FONTS
// - - - - - - - - - - - - - - - - - - - - - - - - -

@font-face {
    font-family: 'mullerbold';
    src: url('../fonts/fontfabric_-_mullerbold-webfont.eot');
    src: url('../fonts/fontfabric_-_mullerbold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/fontfabric_-_mullerbold-webfont.woff2') format('woff2'),
         url('../fonts/fontfabric_-_mullerbold-webfont.woff') format('woff'),
         url('../fonts/fontfabric_-_mullerbold-webfont.ttf') format('truetype'),
         url('../fonts/fontfabric_-_mullerbold-webfont.svg#mullerbold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'mullermedium';
    src: url('../fonts/fontfabric_-_mullermedium-webfont.eot');
    src: url('../fonts/fontfabric_-_mullermedium-webfont?#iefix') format('embedded-opentype'),
         url('../fonts/fontfabric_-_mullermedium-webfont.woff2') format('woff2'),
         url('../fonts/fontfabric_-_mullermedium-webfont.woff') format('woff'),
         url('../fonts/fontfabric_-_mullermedium-webfont.ttf') format('truetype'),
         url('../fonts/fontfabric_-_mullermedium-webfont.svg#mullermedium') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'mullerreg';
    src: url('../fonts/MullerRegular.otf');
    src: url('../fonts/MullerRegular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'icomoon';
    src:    url('../fonts/icomoon.eot?l6bcdc');
    src:    url('../fonts/icomoon.eot?l6bcdc#iefix') format('embedded-opentype'),
        url('../fonts/icomoon.ttf?l6bcdc') format('truetype'),
        url('../fonts/icomoon.woff?l6bcdc') format('woff'),
        url('../fonts/icomoon.svg?l6bcdc#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="ico-"], [class*=" ico-"],[class^="icon-"], [class*=" icon-"]{
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-twitter:before {
    content: "\e904";
}

.icon-instagram:before {
    content: "\e903";
}

.icon-facebook:before {
    content: "\e901";
}

.icon-youtube:before {
    content: "\e905";
}

.icon-linkedin2:before {
    content: "\e900";
}

.icon-instagram:before {
    content: "\e903";
}

.icon-spinner10:before {
    content: "\e91a";
}

.icon-plus:before {
    content: "\e91b";
}

.icon-spinner3:before {
    content: "\e919";
}

.icon-spinner:before {
    content: "\e918";
}

.icon-spinner10:before {
    content: "\e91a";
}

.icon-play2:before {
    content: "\e91d";
}

// ICON LOGOS
.icon-business:before {
    content: "\e910";
}

.icon-consummer:before {
    content: "\e911";
}

.icon-contact:before {
    content: "\e912";
}

.icon-globalnetwork:before {
    content: "\e913";
}

.icon-vehicles:before {
    content: "\e917";
}

.icon-ourprogram:before {
    content: "\e915";
}

.icon-outreach:before {
    content: "\e916";
}

.icon-onlotvehicles:before {
    content: "\e914";
}



@font-face {
    font-family: 'styleicons';
    src:    url('../fonts/styleicons.eot?o1ntgk');
    src:    url('../fonts/styleicons.eot?o1ntgk#iefix') format('embedded-opentype'),
        url('../fonts/styleicons.ttf?o1ntgk') format('truetype'),
        url('../fonts/styleicons.woff?o1ntgk') format('woff'),
        url('../fonts/styleicons.svg?o1ntgk#styleicons') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="style-icon-"], [class*=" style-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'styleicons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.style-icon-activation:before {
    content: "\e908";
}
.style-icon-development:before {
    content: "\e909";
}
.style-icon-metrics:before {
    content: "\e90a";
}
.style-icon-planning:before {
    content: "\e90b";
}
.style-icon-storytelling:before {
    content: "\e90c";
}
.style-icon-climate:before {
    content: "\e900";
}
.style-icon-connectivity:before {
    content: "\e901";
}
.style-icon-design:before {
    content: "\e902";
}
.style-icon-lifts:before {
    content: "\e903";
}
.style-icon-noisereduction:before {
    content: "\e904";
}
.style-icon-oddsandends:before {
    content: "\e905";
}
.style-icon-space:before {
    content: "\e906";
}
.style-icon-unique:before {
    content: "\e907";
}
.style-icon-square-plus:before {
    content: "\e044";
}

