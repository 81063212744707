/*********** INTERIOR STYLE SETS ****************/

#vehicle-styles-gallery.slick-slider {
    height: 520px;

    .slick-list {
        height: 520px;
    }
}

.text-center {
    text-align: center;
}

.interior-page {

    .video-close {
        position: absolute;
        font-size: 30px;
        color: #000;
        top: 20px;
        right: 20px;
        cursor: pointer;
        z-index: 99;
    }

    .header-loader {
        position: relative;
        margin-top: -30px;
        width: 100%;
        height: 100%;
        max-height: 820px;
        overflow: hidden;
        margin-bottom: 60px;
    }

    .page-header {
        position: relative;
        background-color: black;
        min-height: calc(100vw * 0.43);
        /***** BREAK 1099 *****/
        @media screen and (max-width: 1199px) {
            background-color: black;
            min-height: calc(100vw * 0.43);
        }

        @media screen and (max-width: 768px) {
            min-height: unset;
            height: calc(100vw * 1.33);
        }

        .header-videos {
            object-fit: fill;
            position: absolute;
            height: 100%;
            width: 100%;

            @media screen and (max-width: 768px) {
                height: 100%;
                width: auto;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .our-program-banner {
        position: absolute;
        padding-top: 55px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: $white;
        z-index: 10;
        display: none;

        .inner {
            position: relative;
            margin-top: 55px;

            .close-btn {
                position: absolute;
                right: 20px;
                top: 0px;
                color: $orange;
                font-size: 25px;
                @include transitionAll(0.5s);
                cursor: pointer;
            }

            .close-btn:hover {
                color: $grey;
            }

            .table {
                display: table;
                height: 100%;
                width: 100%;
                height: 420px;

                .table-cell {
                    display: table-cell;
                    vertical-align: middle;
                    padding: 1.1em;
                }
            }

            .col-md-6 {
                padding-left: 0px;
            }
        }
    }

    .plus-wrapper {
        position: absolute;
        z-index: 9;
        width: 100%;
        height: 100%;

        .plus {
            position: absolute;
            font-size: 50px;
            color: $orange;
            @include transitionAll(0.5s);
            cursor: pointer;
        }

        .plus:hover {
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
            opacity: 0.6;
        }

        .design {
            top: 48%;
            left: 16.25%;
        }

        .equipment {
            top: 23.4%;
            left: 26.5%;
        }

        .route {
            top: 18.5%;
            left: 49.7%;
        }

        .events {
            top: 72%;
            left: 43.5%;
        }

        .dot {
            top: 25.3%;
            left: 71%;
        }

        .promotional {
            top: 59.3%;
            left: 82.4%;
        }
        /***** BREAK 999 *****/
        @media screen and (max-width: 999px) {
            display: none;
        }
    }
    // our-program
    .program-content {
        display: flex;
        flex-direction: column;
        width: auto;
        padding: 25px 75px 70px;

        img {
            width: auto;
            margin: 5px auto 25px;
        }

        h1 {
            line-height: 24px;
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 40px;
            color: #ec462e;
        }

        p {
            margin-top: 15px;
        }

        .firstrow,
        .secondrow {
            display: flex;
            flex-direction: row;
            margin: 10px auto;
        }

        .left,
        .middle,
        .right {
            width: 32%;
            padding: 35px;
            background-color: #f7f8f8;
            margin: 0 auto;
        }
    }

    .our-program-content {
        img {
            min-height: 64px;
        }
    }

    @media (max-width: 1024px) {
        .program-content {
            width: auto;
            padding: 25px 75px 70px;

            .firstrow,
            .secondrow {
                flex-direction: column;
                margin: 0 auto;
            }

            .left,
            .middle,
            .right {
                width: 90%;
                margin: 10px auto;
            }
        }
    }

    @media (max-width: 768px) {
        .program-content {
            width: auto;
            padding: 25px 75px;

            .left,
            .middle,
            .right {
                width: 100%;
            }
        }
    }

    @media (max-width: 444px) {
        .program-content {
            width: auto;
            padding: 25px;

            .left,
            .middle,
            .right {
                width: 100%;
            }
        }
    }
    // expandable-trailers
    .vehicle-line {
        display: block;
        width: 100%;
        border: 1px solid #b2b2b2;
        margin: 45px auto;
    }

    .multi-expand-trailer ul li {
        /*color: #f8008a;*/
    }

    margin: 0px;

    h4 {
        font-size: 36px;
    }

    h2 {
        font-family: 'mullermedium';
        line-height: 1em;
    }

    h6 {
        font-family: 'mullerbold';
        font-size: 30px;
        color: $black;
        line-height: 1em;
        padding: 0px;
        margin: 0px;
        padding-top: 5px;
    }

    h7 {
        font-family: 'mullermedium';
        font-size: 24px;
        color: #101010;
        line-height: 1em;
        padding: 0px;
        margin: 0px;
        padding-top: 5px;
    }

    h8 {
        font-family: 'mullermedium';
        font-size: 27px;
        color: $white;
        line-height: 1em;
        padding: 0px;
        margin: 0px;
        padding-top: 5px;
    }

    .page-icon-knock {
        font-size: 70px;
        color: $black;
        margin: auto;
        position: relative;
        margin-bottom: 30px;
        width: 100%;
        max-width: 250px;
        height: 105px;
    }
    //
    #the-process {
        position: relative;
        padding-bottom: 60px;
        margin-top: 60px;

        .container {
            width: 1750px;
        }

        ul {
            position: relative;
            margin: 0px;
            padding: 0px;

            li {
                position: relative;
                display: inline-block;
                width: 20%;
                text-align: center;
                padding: 5px 4%;
                vertical-align: top;
                margin-left: -4px;
                list-style: none;
                font-size: 130px;

                .fa {
                    position: relative;
                    font-size: 70px;
                }

                h3 {
                    font-size: 24px;
                    color: $orange;
                    line-height: 24px;
                }
            }
        }
        /***** BREAK 1660 *****/
        @media screen and (max-width: 1660px) {

            .container {
                width: 100%;
            }
        }
        /***** BREAK 999 *****/
        @media screen and (max-width: 1024px) {

            .container {
                width: 100%;
            }

            ul {

                li {
                    width: 100%;
                }
            }
        }
    }
    //
    .tour-gallery-wrapper {
        position: relative;
        margin-top: 60px;
        margin-bottom: 100px;

        .slick-slide {
            margin: 0 10px;
        }

        .slick-list {
            margin: 0 -10px;
        }

        .lSPager {
            margin-top: 5px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%) !important;
            display: flex;
        }

        .lslide {
            position: relative;
            cursor: pointer;
            overflow: hidden;
            background-repeat: no-repeat;
            background-size: contain;
            background-size: cover;
            background-position: center center;
            height: 200px;

            img {
                width: 100%;
            }
        }

        .thumbnail-rail {
            position: relative;
            margin-top: 30px;

            img {
                width: 100%;
            }

            .thumbnail-nav {
                position: absolute;
                top: 45%;
                color: $black;
                width: 100%;
                z-index: 10;

                .control {
                    position: absolute;
                    cursor: pointer;
                    font-size: 20px;
                }

                .right {
                    right: -40px;
                }

                .left {
                    left: -40px;
                }
            }
        }

        .block-inner {
            position: relative;
            cursor: pointer;
            width: 100%;
            height: 100%;
        }

        .popover-title {
            position: absolute;
            z-index: 10;
            padding: 0 20px;
            opacity: 0;
            overflow: hidden;
            background-color: rgba(0,0,0,.8);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            @include transitionAll(.5s);

            h5 {
                color: $white;
                font-size: 18px;
                position: relative;
                vertical-align: middle;
                text-align: center;
            }
        }

        li:hover {
            .popover-title {
                opacity: 1;
            }
        }
        /***** BREAK 999 *****/
        @media screen and (max-width: 999px) {
            width: 100vw;

            .slick-slide {
                margin: 0 5px;
            }

            .slick-list {
                margin: 0 -10px;
            }

            li {
                position: relative;
                width: 100%;
                height: 150px;
            }

            li .popover-title {
                padding: 10%;
                height: 150px;
            }

            .plus-icon {
                display: none;
            }


            .thumbnail-nav {
                position: absolute;
                top: 40%;
                color: $black;
                width: 100%;
                z-index: 999;


                .right {
                    right: 40px !important;
                    font-size: 50px;
                }

                .left {
                    left: 30px !important;
                    font-size: 50px;
                }
            }

            .slick-slider {
                max-width: 90%;
                margin: auto;
            }
        }
    }
    // overrides styles for healthcare pages
    .single-image .tour-gallery-wrapper {
        margin-top: 0;
        margin-bottom: 0;

        #title {
            line-height: 36px;
            margin: 0;
            margin-bottom: 20px;
        }

        .lslide {
            height: 400px;

            @media screen and (max-width: 1200px) {
                height: 300px;
            }
        }

        .thumbnail-rail {
            margin-top: 0;
        }
    }
    //
    .plus-list {

        ul {
            position: relative;
            width: 90%;

            li {
                position: relative;
                list-style: none;
                margin-bottom: 3px;
                cursor: pointer;

                .content-hidden {
                    display: none;
                }

                .list-title {
                    color: #fff;
                    text-transform: uppercase;
                    background-color: #1f1e24;
                    padding: 10px;
                    border: solid 1px #79787c;
                }

                .class-img {
                    position: relative;
                    margin-top: 5px;
                }

                .plus-sign {
                    background-color: #000;
                    margin-right: 10px;
                    position: relative;
                    display: inline-block;
                    padding: 10px;
                }
            }
            /***** BREAK 999 *****/
            @media screen and (max-width: 999px) {
                width: 95%;
            }
        }
    }
    /***** BREAK 999 *****/
    @media screen and (max-width: 1199px) {

        .header-loader {
            min-height: calc(100vw * 0.43);
            background-repeat: no-repeat;
            background-size: contain;
        }

        .page-icon-knock {
            font-size: 30px;
            max-width: 100px;
            height: 55px;
        }
    }
    /***** BREAK 768 *****/
    @media screen and (max-width: 768px) {

        .header-loader {
            margin-top: 0px;
        }
    }
    /***** BREAK 480 *****/
    @media screen and (max-width: 480px) {

        .header-loader {
            /*max-height: 150px;*/
            margin-top: 0px;
        }
    }
}

.vehicle-styles-nav {
    background-color: #efefef;
    width: 100%;
    padding-left: 7px;
    padding-right: 7px;
}

.vehicle-styles-nav ul {
    list-style: none;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding-left: 0;
}
// healthcare page overrides
.vehicle-styles-nav.healthcare-nav {
    padding-left: 0;
    padding-right: 0;

    ul {
        max-width: 1200px;
        display: flex;
        padding: 0px 20px;

        @media(max-width: 1000px) {
            flex-direction: column;
        }

        li {
            @media(max-width: 1000px) {
                margin: 12px auto;
            }

            a {
                padding: 20px 5px;
                height: 100%;
            }
        }
    }
}

.vehicle-styles-nav ul::after {
    content: " ";
    display: block;
    clear: both;
}

.vehicle-styles-nav ul li.menu-item-extra-width {
    float: left;
    display: block;
    width: 25%;
}

.vehicle-styles-nav ul li {
    float: left;
    display: block;
    width: 15%;
}


.vehicle-styles-nav.special ul li {
    width: 33.33%;
}

.vehicle-styles-nav ul li a {
    display: block;
    width: 100%;
    padding: 20px 0;
    text-align: center;
    outline: none !important;
    text-decoration: none !important;
}

.vehicle-styles-nav ul li a.active,
.vehicle-styles-nav ul li a:hover {
    background-color: #1f1e24;
    color: #FFF;
}

.small-header .full-list-item {
    height: 395px;
}

.image-and-desc {
}

.image-and-desc h4 {
    color: #ec452e;
    margin: 0;
    font-size: 22px;
    line-height: 22px;
}

.image-and-desc ul {
    background-color: #f2f2f2;
    list-style: none;
    padding: 20px 20px 20px 40px;
}

.image-and-desc ul li {
    position: relative;
    font-weight: 400;
    font-size: 18px;
    font-family: TimesNewRoman,'Times New Roman',Times,Baskerville,Georgia,serif;
    line-height: 20px;
    margin: 10px 0;
}

.image-and-desc ul li::before {
    content: " ";
    display: block;
    background-color: #ec452e;
    border-radius: 100%;
    width: 5px;
    height: 5px;
    position: absolute;
    top: 8px;
    left: -15px;
}

#steam h1 {
    font-size: 71px;
    line-height: 71px;
}

#steam h3 {
    font-size: 24px;
    line-height: 24px;
    color: #ec462e;
}

.careers-details .image-and-desc {
    p {
        padding-bottom: 20px;
    }

    ul {
        padding: 0 20px 20px 40px;
    }
}

@media (max-width: 1199px) {
    .interior-page .header-loader.steam {
        max-height: 36.8vw;
    }

    .interior-page .header-loader.steam .background {
        background-size: 100%;
    }
}

@media (max-width: 1000px) {
    .vehicle-styles-nav.healthcare-nav ul li, .vehicle-styles-nav.special ul li {
        width: 100% !important;
    }
}

@media (max-width: 800px) {
    .vehicle-styles-nav ul li, .vehicle-styles-nav ul li.menu-item-extra-width, .vehicle-styles-nav.special ul li {
        width: 100%;
    }

    .interior-page .header-loader.small-header {
        max-height: calc(100vw / 4.755);
    }

    .image-and-desc h4 {
        padding: 10px 20px;
    }

    .interior-page .tour-gallery-loader h4 {
        font-size: 30px;
        line-height: 30px;
        margin: 0;
        left: 20px;
        position: relative;
    }

    #steam {
        padding: 0 30px;
    }

    #steam h1 {
        font-size: 36px;
        line-height: 36px;
        margin-top: -40px;
    }

    #steam h3 {
        font-size: 18px;
        line-height: 18px;
        color: #ec462e;
    }

    .interior-page .header-loader.steam {
        max-height: 85.54vw;
    }
}

.expandable-container-page {
    h2 {
        font-size: 55px;
        max-width: 85%;
        margin: auto;
        padding-top: 5px;
    }

    p {
        font-size: 20px;
    }
}
