// MIXINS & FUNCTIONS
// - - - - - - - - - - - - - - - - - - - - - - - - -

$rem-base: $body-base-font-size;

// STRIP UNIT
// It strips the unit of measure and returns it
@function strip-unit($num) {
    @return $num / ($num * 0 + 1);
}

// CONVERT TO REM
@function convert-to-rem($value, $base-value: $rem-base) {
    $value: strip-unit($value) / strip-unit($base-value) * 1rem;

    @if ($value == 0rem) {
        $value: 0;
    }
    // Turn 0rem into 0
    @return $value;
}

@function rem-calc($values, $base-value: $rem-base) {
    $max: length($values);

    @if $max == 1 {
        @return convert-to-rem(nth($values, 1), $base-value);
    }

    $remValues: ();

    @for $i from 1 through $max {
        $remValues: append($remValues, convert-to-rem(nth($values, $i), $base-value));
    }

    @return $remValues;
}

@mixin drop-shadow($color) {
    -moz-box-shadow: 0px 0px 6px $color;
    -webkit-box-shadow: 0px 0px 6px $color;
    box-shadow: 0px 0px 6px $color;
    -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=3, Direction=0, Color='$color')";
    filter: progid:DXImageTransform.Microsoft.Shadow(Strength=3, Direction=0, Color='$color');
}

@mixin vertical-align($position: relative) {
    position: $position;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin transition($property, $duration: 300ms, $ease: linear) {
    -webkit-transition: $property $duration $ease;
    -moz-transition: $property $duration $ease;
    -ms-transition: $property $duration $ease;
    -o-transition: $property $duration $ease;
    transition: $property $duration $ease;
}

@mixin transitionAll($speed) {
    -webkit-transition: -webkit-transform $speed ease-in-out;
    -moz-transition: -moz-transform $speed ease-in-out;
    -o-transition: -o-transform $speed ease-in-out;
    transition: all $speed ease-in-out;
}

@mixin transform-origin($x,$y) {
    transform-origin: $x $y;
    -ms-transform-origin: $x $y;
    -webkit-transform-origin: $x $y;
    -moz-transform-origin: $x $y;
}

// PRELOADER
// - - - - - - - - - - - - - - - - - - - - - - - - -

.lSPager, .banner, .wrap {
    @include opacity(0);
    @include transition(opacity, 0.6s, linear);
}

body.loaded {
    .lSPager, header, .banner, .wrap {
        @include opacity(1);
    }
}

.loader {
    position: fixed;
    display: block;
    z-index: 99;
    width: 100%;
    height: 100%;
    background-color: #141414;
    background-repeat: no-repeat;

    .loading-spinner {
        font-size: 30px;
        color: $white;
        position: absolute;
        margin: auto;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        width: 30px;
        height: 30px;
        -webkit-animation: spin 1s infinite linear;
    }
}


.mobile-only {
    display: none;
}

.line-height {
    line-height: 1.3em;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(360deg)
    }
}

// COLORS / BACKGROUNDS / GRADIENTS ETC.
// - - - - - - - - - - - - - - - - - - - - - - - - -
body, html {
    overflow-x: hidden;
}

body {
    background-color: $white;
    color: #fff;
}

.black {
    color: $black !important;
}

.black-border {
    border-color: $black !important;
}

.white {
    color: $white;
}

.orange {
    color: $orange;
}

.main {
    position: relative;
    margin-top: 55px;
}

.lSPager {
    z-index: 1;
}
// TYPOGRAPHY
// - - - - - - - - - - - - - - - - - - - - - - - - -


body {
    font-family: $body-font;
    font-size: $body-base-font-size;
    line-height: 1.1em;
    color: $grey;
}

.light {
    font-weight: 300;
}

.regular {
    font-weight: 400;
}

.bold {
    font-weight: 700;
}

.lrg {
    font-size: 71px;
    line-height: 71px;
}

.grey-bg {
    background-color: $lightGrey;
}

.center {
    text-align: center;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $header-font;
    text-transform: uppercase;
    font-weight: 200;
}

p {
    font-family: 'TimesNewRoman', 'Times New Roman', Times, Baskerville, Georgia, serif;
    line-height: 1.3em;
    color: #2d2929;
    font-size: 18px;
}

.large-container {
    max-width: 1750px;
    margin: auto;
}

.margin-top-60 {
    margin-top: 60px;
}

.inner-copy-width {
    max-width: 76%;
    margin: auto;
}


// button, input, select, textarea {
//     font-family: inherit;
//     font-size: inherit;
//     line-height: inherit;
//     background-color: #000;
// }

.title-top-border-sm {
    border-top: solid 1px $lightGrey;
    position: relative;
    display: block;
    width: 160px;
    height: 10px;
}

.title-top-border-lrg {
    border-top: solid 1px $lightGrey;
    position: relative;
    display: block;
    width: 380px;
    height: 10px;
    margin: 60px auto;
    text-align: center;
}

.margin-right {
    margin-right: 20px;
}


// GLOBAL ELEMENT STYLES
// - - - - - - - - - - - - - - - - - - - - - - - - -


.image {
    display: block;
    position: relative;
    z-index: 0;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    &.background {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}


.full-list-item {
    position: relative;
    width: 100%;
    height: 820px;
    background-position: top center;
    background-size: cover;
    box-shadow: inset 900px 150px 20em -5em rgba(0,0,0,0.55);
    /***** BREAK 1199 *****/
    @media screen and (max-width: 1199px) {
        background-position: top center;
        background-size: contain;
        background-repeat: no-repeat;
        height: calc(100vw * 0.43);
        box-shadow: inset calc(100vh * 0.6) 1em 20em -5em rgba(0,0,0,.55);
    }
    /***** BREAK 1199 *****/
    @media screen and (max-width: 768px) {
        box-shadow: inset calc(100vh * 0.3) 1em 20em -5em rgba(0,0,0,.55);
        height: calc(100vw * 1.33);
        background-size: cover;
    }
}

.contain {
    background-size: contain !important;
    background-repeat: no-repeat !important;
    height: 420px !important;
}

.center-form {
    position: relative;
    margin: auto;
    width: 550px;
    display: block;
}





// MEDIA QUERIES
// - - - - - - - - - - - - - - - - - - - - - - - - -

$screen-xs-min: 320px;
$screen-sm-min: 480px;
$screen-md-min: 768px;
$screen-lg-min: 1024px;
$screen-xl-min: 1280px;

@mixin xs-max {
    @media (max-width: $screen-xs-min) {
        @content;
    }
}

@mixin sm-max {
    @media (max-width: $screen-sm-min) {
        @content;
    }
}

@mixin md-max {
    @media (max-width: $screen-md-min) {
        @content;
    }
}

@mixin lg-max {
    @media (max-width: $screen-lg-min) {
        @content;
    }
}

@mixin xls-max {
    @media (max-width: $screen-xls-min) {
        @content;
    }
}

@mixin xl-max {
    @media (max-width: $screen-xl-min) {
        @content;
    }
}

@mixin xl-min {
    @media (min-width: $screen-xl-min) {
        @content;
    }
}

.not-on-mobile {
}

/***** BREAK 1199 *****/
@media screen and (max-width: 1199px) {
}

/***** BREAK 999 *****/
@media screen and (max-width: 999px) {

    .not-on-mobile {
        display: none;
    }

    .lrg {
        font-size: 35px;
        line-height: 35px;
    }

    .title-top-border-lrg {
        width: 50%;
    }

    .mobile-only {
        display: block;
    }
}

/***** BREAK 499 *****/
@media screen and (max-width: 499px) {


    button.subscribe-btn {
        width: 100%;
    }
}

/***** BREAK 499 *****/
@media screen and (max-width: 375px) {

    .lrg {
        font-size: 25px;
        line-height: 25px;
    }
}
